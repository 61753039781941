import watchlistIcon from "../assets/icons/watchlist.svg"
import supportIcon from "../assets/icons/support.svg"
import devicesIcon from "../assets/icons/devices.svg"
import profilesIcon from "../assets/icons/profiles.svg"

export const genreTabItems = [
    "All",
    "Drama",
    "Action",
    "Comedy"
]

export const PROFILE_TAB_ITEMS = [
    {
        title: "Watchlist",
        icon: watchlistIcon,
        value: "watchlist",
        iconAlt: "watchlist_icon",
    },
    {
        title: "Devices",
        icon: devicesIcon,
        value: "devices",
        iconAlt: "devices_icon",
    },
    {
        title: "Profiles",
        icon: profilesIcon,
        value: "profile",
        iconAlt: "profiles_icon",
    },
    {
        title: "Support",
        icon: supportIcon,
        value: "support",
        iconAlt: "support_icon",
    },
]