import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useParams } from "react-router-dom"
import { getVideo, updateWatchlist } from "../../redux/functions/vod"
import { sendLog, sendPlayLogs } from "../../redux/functions/auth"
import ReactPlayer from "react-player"
import Header from "../../components/Header"
import useQuery from "../../components/hooks/useQuery"
import VideoPlayer from "../../components/VideoPlayer"

const Watch = () => {
    // const dispatch = useDispatch()
    // const location = useLocation()
    // const query = useQuery()

    // const { id, type } = useParams()
    // const [videoSource, setVideoSource] = useState("")
    // const [isLiveTv, setIsLiveTv] = useState(true)
    // const [showTitle, setShowTitle] = useState(true)

    // const [secondsPlayed, setSecondsPlayed] = useState(0)
    // const [lengthWatchedInMs, setLengthWatchedInMs] = useState(0)

    // useEffect(() => {
    //     const route = location.pathname
    //     if (route.substring(0, 12) === '/watch/live/') setIsLiveTv(true)
    //     else setIsLiveTv(false)
    // }, [location.pathname])

    // useEffect(() => {
    //     const initGetVideo = async () => {
    //         let videoRes = await getVideo(id, type, dispatch)
    //         setVideoSource(videoRes.url)
    //         await sendPlayLogs(id, query.get("i"), type, dispatch, 0)
    //     }
    //     initGetVideo()
    // }, [])

    // const setProgressInMs = (e) => {
    //     const _lengthWatchedInMs = (e.played * 100).toFixed(0) * 10
    //     setLengthWatchedInMs(_lengthWatchedInMs)
    //     initSendPlayLogs(e.playedSeconds.toFixed(0))
    //     setSecondsPlayed(e.playedSeconds.toFixed(0))
    // }

    // const initSendPlayLogs = async (x) => {
    //     //* check if playtime is 60seconds (value is a multiple of 60)
    //     let remainder = x % 60
    //     if (remainder === 0) sendPlayLogs(id, query.get("i"), type, dispatch, x)
    // }

    // const initUpdateWatchlist = () => {
    //     if (type === 'series') updateWatchlist(id, 'series', lengthWatchedInMs, true)
    //     if (type === 'movie') updateWatchlist(id, 'movie', lengthWatchedInMs, true)
    // }

    // const onMovieEnd = async () => {
    //     const _secondsInt = secondsPlayed
    //     _secondsInt.replace(',', '')
    //     await sendPlayLogs(id, query.get("i"), type, dispatch, _secondsInt)
    //     if (nextEpisodeId) setShowNextPopup(true)
    //     // alert('movie ended')
    // }

    const dispatch = useDispatch()
    const location = useLocation()
    const query = useQuery()
    const params = useParams()

    const { id, type } = useParams()
    const [videoSource, setVideoSource] = useState("")
    const [isLiveTv, setIsLiveTv] = useState(true)
    const [lengthWatchedInMs, setLengthWatchedInMs] = useState(0)
    const [secondsPlayed, setSecondsPlayed] = useState(0)

    useEffect(() => {
        const route = location.pathname
        if (route.substring(0, 12) === '/watch/live/') setIsLiveTv(true)
        else setIsLiveTv(false)
    }, [location.pathname])

    useEffect(() => {
        const initGetVideo = async () => {
            let videoRes = await getVideo(id, type, dispatch, params.id, query.get("title") || "")
            if (videoRes) setVideoSource(videoRes.url)
        }
        initGetVideo()
    }, [])

    const setProgressInMs = (e) => {
        // console.warn("setProgressInMs", e.currentTime)
        const _lengthWatchedInMs = (e.currentTime * 100).toFixed(0) * 10
        setLengthWatchedInMs(_lengthWatchedInMs)
        initSendPlayLogs(e.currentTime.toFixed(0))
        setSecondsPlayed(e.currentTime.toFixed(0))
    }

    const initSendPlayLogs = async (x) => {
        // console.warn("initSendPlayLogs", x)
        //* check if playtime is 60seconds (value is a multiple of 60)
        let remainder = x % 60
        if (remainder === 0)
            await sendLog({
                action: 'play',
                content_uid: id,
                content_type: type,
                content_name: query.get("t") || "",
                duration: Number(x)
            })
    }

    const initUpdateWatchlist = async () => {
        // console.warn("initUpdateWatchlist")
        if (type === 'series') updateWatchlist(id, 'series', lengthWatchedInMs, true)
        if (type === 'movie') updateWatchlist(id, 'movie', lengthWatchedInMs, true)

        await sendLog({
            action: 'play',
            content_uid: id,
            content_type: type,
            content_name: query.get("t") || "",
            duration: Number(lengthWatchedInMs)
        })
    }

    const onMovieEnd = async () => {
        // console.warn("onMovieEnd")
        const _secondsInt = secondsPlayed
        _secondsInt.replace(',', '')
        await sendLog({
            action: 'play',
            content_uid: id,
            content_type: type,
            content_name: query.get("t") || "",
            duration: Number(_secondsInt)
        })
    }

    return (
        <main className="bg-[#000]">
            {/* <Header /> */}
            <wc-toast></wc-toast>

            {/* {
                showTitle
                    ? <div className="w-screen bg-[#20133e] fixed z-[50] top-0 left-0">
                        <div className="max-w-[1900px] text-sm m-auto text-center py-3 px-5 flex items-center justify-between">
                            <div onClick={() => window.history.back()} className="text-[#fff] hover:opacity-70 transition-all cursor-pointer">&larr;</div>
                            <p className="max-lines-1 text-sm lg:text-xl">
                                {query.get("t")}
                            </p>
                            <p onClick={() => setShowTitle(false)} className="text-3xl hover:opacity-50 transition-all cursor-pointer">&times;</p>
                        </div>
                    </div>
                    : <></>
            } */}

            <VideoPlayer
                onEnded_={onMovieEnd}
                onPause_={initUpdateWatchlist}
                onSeek_={initUpdateWatchlist}
                onStart_={initUpdateWatchlist}
                onBufferEnd_={initUpdateWatchlist}
                onPlay_={initUpdateWatchlist}
                onProgress_={setProgressInMs}
                title={query.get("t") || ""}
                liveURL={videoSource}
            />

            {/* <ReactPlayer
                url={videoSource}
                width="100vw"
                height="90vh"
                muted={false}
                autoPlay={true}
                playing={true}
                volume={1}
                controls={isLiveTv ? false : true}

                onProgress={setProgressInMs}
                onPlay={initUpdateWatchlist}
                onBufferEnd={initUpdateWatchlist}
                onEnded={onMovieEnd}
                onPause={initUpdateWatchlist}
                onSeek={initUpdateWatchlist}
                onStart={initUpdateWatchlist}
            /> */}
        </main>
    )
}

export default Watch