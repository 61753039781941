import { Link } from "react-router-dom"

const OutlineButton = ({ label, action, page }) => {
    if (action) return (
        <button onClick={action} className={styles.btn}>
            {label}
        </button>
    )

    if (page) return (
        <Link className={styles.btn} to={page}>
            {label}
        </Link>
    )

    return <></>
}

const styles = {
    btn: ` text-[#fff] font-medium border-2 border-[#fff] text-sm active:scale-90 hover:opacity-70 transition-all whitespace-nowrap flex items-center justify-center py-[10px] px-4 rounded-md`
}

export default OutlineButton

// import { Link } from "react-router-dom"

// const OutlineButton = ({ label, action, page }) => {
//     if (action) return (
//         <button onClick={action} className={styles.btn}>
//             {label}
//         </button>
//     )

//     if (page) return (
//         <Link className={styles.btn} to={page}>
//             {label}
//         </Link>
//     )

//     return <></>
// }

// const styles = {
//     btn: `border-2 font-medium hover:border-brand hover:text-brand text-sm active:scale-90 hover:opacity-70 transition-all whitespace-nowrap flex items-center justify-center py-[3px] px-4 rounded-[3px]`
// }

// export default OutlineButton