import Footer from "../components/Footer"

const PageNotFound = () => {
    return (
        <>
            <div>Page not found</div>
            <Footer />
        </>
    )
}

export default PageNotFound