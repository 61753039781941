import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Card from "../../components/Cards/Card"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import { sendLog } from "../../redux/functions/auth"
import { getSearchResults } from "../../redux/functions/vod"
import vodStyles from "../../styles/vodGrid.style"
// import Loader from "../../components/Loader"

const Search = () => {
    const dispatch = useDispatch()
    const { searchQuery } = useSelector(state => state.ui)
    const [searchResults, setSearchResults] = useState([])


    // console.log(searchResults)
    // const [searchResults, setSearchResults] = useState([])
    // console.log(searchQuery)
    // if (searchResultsLoading) return (
    //     <>
    //         <Header />
    //         <Loader />
    //     </>
    // )

    useEffect(() => {
        const initGetSearchResults = async () => {
            let results = await getSearchResults(searchQuery, dispatch)
            if (results) setSearchResults(results)

            await sendLog({ action: 'search' })
        }
        initGetSearchResults()
    }, [searchQuery])

    // if (!searchResults) return (<></>)

    return (
        <>
            <Header />
            <wc-toast></wc-toast>
            {searchResults.length > 1 ?
                <div className={vodStyles.gridWrapper}>
                    <div className={vodStyles.grid}>
                        {searchResults.map((vod, i) => {
                            vod.type = "movie"
                            return <Card
                                key={i}
                                content={vod}
                                isGridItem
                            />
                        })}
                    </div>
                </div>
                : <></>}
            <Footer />
        </>
    )
}

export default Search