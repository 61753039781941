import { useState } from "react"
import BannerBackground from "../BannerBackground"
import Button from "../buttons/Button"
import mute from "../../assets/icons/mute.svg"
import speaker from "../../assets/icons/speaker.svg"

const LiveTVBanner = ({ content }) => {
    const { id, description, bgImage } = content

    const [showDescription, setShowDescription] = useState(true)
    const [playVideo, setPlayVideo] = useState(true)
    const [isPlaying, setIsPlaying] = useState(true)
    const [isMuted, setIsMuted] = useState(true)
    const [trailer, setTrailer] = useState("")

    // useEffect(() => {
    //     const initGetSlideTrailer = async () => {
    //         setShowDescription(true)
    //         setTrailer(await getTrailer(selectedFeatureSlide.id, selectedFeatureSlide.type))
    //         togglePlayVideo()
    //     }
    //     initGetSlideTrailer()
    // }, [selectedFeatureSlide])

    // const togglePlayVideo = () => {
    //     if (!trailer) {
    //         console.log("NO TRAILER: ", trailer, description)
    //         setPlayVideo(false)
    //         setIsPlaying(false)
    //         setShowDescription(true)
    //         return
    //     }

    //     console.log("PLAYING TRAILER: ", trailer)

    //     setPlayVideo(!playVideo)
    //     setIsPlaying(!isPlaying)

    //     setTimeout(() => {
    //         setShowDescription(false)
    //     }, 6000)
    // }

    // console.log(content)

    return (
        <div className={styles.banner}>
            <div className={styles.bannerWrapper}>
                <div className={styles.bannerContent}>
                    <small className="text-secondary">{content.type}</small>
                    <h1 className={styles.h1}>{content.name}</h1>
                    <div className="m-6" />
                    {showDescription ? <p>{description}</p> : <></>}
                    {/* <div className="m-6" /> */}
                    <div className="flex items-center">
                        <Button
                            page={`/watch/live/${content.uid}?i=${content.id}&t=${content.name}`}
                            label="Watch Live"
                        />
                        {/* <div className="m-2" />
                        <OutlineButton
                            page={`/vod/movie/${id}`}
                            label="Info"
                        /> */}
                        {isPlaying ? <div className="ml-3 cursor-pointer">
                            {
                                isMuted
                                    ? <img onClick={() => { setIsMuted(!isMuted) }} src={speaker} alt="speaker_icon" />
                                    : <img onClick={() => { setIsMuted(!isMuted) }} src={mute} alt="mute_icon" />
                            }
                        </div> : <></>}
                    </div>
                </div>
            </div>
            <BannerBackground
                video="https://glonigeria.tvanywhereafrica.com:28182/auth-streaming…riplayghana,172.20.1.31/playlist/0-france241/live/index.m3u8"
                playVideo={playVideo}
                isPlaying={isPlaying}
                bgImage={bgImage}
                muted={isMuted}
                id={id}
            />
        </div>
    )
}

const styles = {
    banner: `text-[#fff] bg-[#000] lg:pt-0 lg:h-[100vh] flex flex-col items-center`,
    bannerWrapper: `z-10 w-screen max-w-[1900px] m-auto`,
    bannerContent: `max-w-2xl p-5 h-screen flex flex-col justify-center`,
    bannerBgImg: `absolute top-0 left-0 h-full w-full object-cover`,
    h1: `text-3xl lg:text-5xl font-medium`,
}

// const styles = {
//     banner: `text-[#fff] bg-[#000] pt-[100px] lg:pt-0 h-[50vh] lg:h-[100vh] flex flex-col items-center lg:mt-[30px] -mt-[60px]`,
//     bannerWrapper: `z-10 w-screen max-w-[1900px] m-auto`,
//     bannerContent: `max-w-2xl p-5`,
//     bannerBgImg: `absolute top-0 left-0 h-full w-full object-cover`,
//     h1: `text-3xl lg:text-5xl font-medium`,
// }

export default LiveTVBanner