import { Routes, Route, BrowserRouter } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import routes from "./constants/routes.const";
import './index.css';
import Signup from "./pages/auth/signup";
import Verify from "./pages/auth/verify";
import Account from "./pages/authorized/account";
import Home from "./pages/authorized/home";
import LiveTV from "./pages/authorized/livetv";
import Movies from "./pages/authorized/movies";
import Search from "./pages/authorized/search";
import Movie from "./pages/authorized/vod/movie";
import Series from "./pages/authorized/vod/series";
import Watch from "./pages/authorized/watch";
import Landing from "./pages/landing";
import PageNotFound from "./pages/pageNotFound";
import Policy from "./pages/policy";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.home} element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path={routes.movies} element={<ProtectedRoute><Movies /></ProtectedRoute>} />
        <Route path={routes.livetv} element={<ProtectedRoute><LiveTV /></ProtectedRoute>} />
        <Route path={routes.account} element={<ProtectedRoute><Account /></ProtectedRoute>} />
        <Route path={routes.policy} element={<Policy />} />
        <Route path="/search" element={<ProtectedRoute><Search /></ProtectedRoute>} />
        <Route path="/vod/movie/:id" element={<ProtectedRoute><Movie /></ProtectedRoute>} />
        <Route path="/vod/series/:id" element={<ProtectedRoute><Series /></ProtectedRoute>} />
        <Route path="/watch/:type/:id" element={<ProtectedRoute><Watch /></ProtectedRoute>} />

        <Route path={routes.signup} element={<Signup />} />
        <Route path={routes.verifyOTP} element={<Verify />} />

        <Route path="*" element={<PageNotFound />} />
        <Route index element={<Landing />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
