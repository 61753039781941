export const faqContentStyle = {
    title: `font-bold text-[500] mb-1`,
    content: `mb-5 text-sm`
}

export const faqContent = {
    title: "Frequently asked questions",
    rows: [
        {
            title: " WHAT IS AFRIPLAY?",
            content: `Afriplay is a video streaming entertainment service offering unlimited access to TV shows, movies, live TV channels, and more. Stream and watch on a wide range of devices, just the way you would like to.No commitments, no hassles, and an all- pass to enjoyable satisfying content. Enjoy unlimited access using any of the six(6) available plans: Afriplay Basic, Basic +, Compact, Standard, Pro, Max.`,
        },
        {
            title: "WHERE CAN I WATCH?",
            content: "To start watching, visit www.afriplay.com or go to the app store on your device, search ‘Afriplay’, select and download. Whichever plan you choose, you get to the option to choose which device you’d best enjoy your streaming on.Of course, you can choose to modify your plan at any time.The choice is completely yours.You can watch it on several devices, including smartphones, tablets, computers, smart TVs, and media boxes. It’s really your call.We aim to provide a great experience – whichever devices you choose.",
        },
        {
            title: "WHAT CAN I WATCH?",
            content: `Movies, Series, TV Shows, Live TV Channels, Live Concerts. To start watching, sign up at www.afriplay.com and follow the easy steps. Once you’re signed in, select one from the available plans.Once you’re a subscriber, you can watch from the available content under the plan you’re subscribed to.`,
        },
        {
            title: "CAN I WATCH WITHOUT DATA OR WIFI?",
            content: "You will need reliable internet connectivity to enjoy AFRIPlay. You may use Wi-Fi and/or data. You can also manage how much data consumption you would like to have as you watch. Simply go to Settings -> Video Quality -> choose from any of the options outlined.",
        },
        {
            title: "CAN I CATCH UP OR RECORD TV PROGRAMS?",
            content: <div className="text-sm">Yes. For Catch up, simply click on the channel of choice &gt; scroll upwards to choose a previously ran program on the Electronic Programming Guide (EPG) &gt; click on the program of choice &gt; Click Yes button to the pop-up prompt &gt; Enjoy your program Catch Up. <br /><br /> To Record, simply click on the channel of choice &gt; scroll downwards to choose a yet to run program on the Electronic Programming Guide (EPG) &gt; click on the program of choice &gt; Click Yes button to the pop-up prompt &gt; When the program is running, the recording will begin.</div>,
        },
        {
            title: "WHAT PLANS ARE AVAILABLE?",
            content: <div>
                <div className="text-sm">
                    <h5 className={faqContentStyle.title}>Afriplay BASIC</h5>
                    <p className={faqContentStyle.content}>One-day access to enjoy selected live Channels</p>
                </div>
                <div className="text-sm">
                    <h5 className={faqContentStyle.title}>Afriplay BASIC+</h5>
                    <p className={faqContentStyle.content}>One-day access to enjoy selected movies, TV shows and live Channels</p>
                </div>
                <div className="text-sm">
                    <h5 className={faqContentStyle.title}>Afriplay COMPACT</h5>
                    <p className={faqContentStyle.content}>One (1)-day access to enjoy selected movies, TV shows and premium live Channels</p>
                </div>
                <div className="text-sm">
                    <h5 className={faqContentStyle.title}>Afriplay STANDARD</h5>
                    <p className={faqContentStyle.content}>Three (3)-day access to enjoy premium movies, TV shows and live Channels</p>
                </div>
                <div className="text-sm">
                    <h5 className={faqContentStyle.title}>Afriplay PRO</h5>
                    <p className={faqContentStyle.content}>Seven (7)-day access to enjoy premium movies, TV shows and live Channels</p>
                </div>
                <div className="text-sm">
                    <h5 className={faqContentStyle.title}>Afriplay MAX</h5>
                    <p className={faqContentStyle.content}>Thirty (30)-day access to enjoy premium movies, TV shows and live Channels</p>
                </div>
                <p className="text-sm">You will be presented with all the options upon successful sign-up.</p>
            </div>
        },
    ],
}

export const faqStyles = {
    bgColor: 'transparent',
    titleTextColor: "#fff",
    // rowTitleColor: "blue",
    // rowContentColor: 'grey',
    // arrowColor: "red",
    // outterWidth: "100px"
}

export const faqConfig = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
}