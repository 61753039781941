import { useEffect, useState } from "react"
// import { AppContext } from "../../context"
import { getTrailer } from "../../redux/functions/vod"
import { useLocation } from "react-router-dom"
// import getVODImage from "../../utils/vod/getVODImage.util"
import BannerBackground from "../BannerBackground"
import Button from "../buttons/Button"
import mute from "../../assets/icons/mute.svg"
import speaker from "../../assets/icons/speaker.svg"
import OutlineButton from "../buttons/OutlineButton"
// import ReactPlayer from "react-player"

const Banner = ({ content, children }) => {
    const location = useLocation()

    const { id, title, description, watchLink, bgImage } = content

    const [showDescription, setShowDescription] = useState(true)
    const [playVideo, setPlayVideo] = useState(false)
    const [isPlaying, setIsPlaying] = useState(true)
    const [isMuted, setIsMuted] = useState(true)
    const [trailer, setTrailer] = useState("")

    useEffect(() => {
        const initGetSlideTrailer = async () => {
            setShowDescription(true)
            setTrailer(await getTrailer(content.id, content.type))
            // togglePlayVideo()
        }
        initGetSlideTrailer()
    }, [content.id])

    useEffect(() => {
        const initVideoPlay = async () => {


            if (!trailer) {
                // console.log("NO TRAILER: ", trailer, description)
                setPlayVideo(false)
                setIsPlaying(false)
                setShowDescription(true)
                return
            }

            // console.log("PLAYING TRAILER: ", trailer)

            setPlayVideo(true)
            setIsPlaying(true)

            setTimeout(() => {
                setShowDescription(false)
            }, 6000)


        }
        initVideoPlay()
    }, [trailer, isPlaying, playVideo])



    // const togglePlayVideo = () => {
    //     if (!trailer) {
    //         // console.log("NO TRAILER: ", trailer, description)
    //         setPlayVideo(false)
    //         setIsPlaying(false)
    //         setShowDescription(true)
    //         return
    //     }

    //     // console.log("PLAYING TRAILER: ", trailer)

    //     setPlayVideo(!playVideo)
    //     setIsPlaying(!isPlaying)

    //     setTimeout(() => {
    //         setShowDescription(false)
    //     }, 6000)
    // }

    const BannerContent = () => {
        return (
            <div className={location.pathname === "/movies" ? "h-full mb-20" : "h-full"}>
                <div className="m-auto px-5 h-full max-w-[1900px] z-10 relative">
                    <div className="h-full flex flex-col justify-center max-w-[420px]">
                        <h1 className="text-xl lg:text-4xl font-[500] mb-4">{title}</h1>
                        {
                            showDescription
                                ? <h1 className="mb-4 text-[14px] max-lines-3">{description}</h1>
                                : <></>
                        }
                        <div className="flex items-center">
                            <Button
                                page={watchLink}
                                label="Play"
                            />
                            <div className="m-2" />
                            {
                                id ? <OutlineButton
                                    page={`/vod/movie/${id}`}
                                    label="Info"
                                />
                                    : <></>
                            }
                            {isPlaying ? <div className="ml-3 cursor-pointer">
                                {
                                    isMuted
                                        ? <img onClick={() => { setIsMuted(!isMuted) }} src={speaker} alt="speaker_icon" />
                                        : <img onClick={() => { setIsMuted(!isMuted) }} src={mute} alt="mute_icon" />
                                }
                            </div> : <></>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="bg-[#090016] lg:h-[80vh] h-[50vh] relative">
                <BannerContent />
                <BannerBackground
                    playVideo={playVideo}
                    isPlaying={isPlaying}
                    bgImage={bgImage}
                    video={trailer}
                    videoMuted={isMuted}
                />
                {children}
            </div>
        </>
        // <div className={location.pathname === "/home" ? "lg:-mt-[80px]" : ""}>
        //     <div className={styles.banner}>
        //         <div className={styles.bannerWrapper}>
        //             <div className={styles.bannerContent}>
        //                 <h1 className={styles.h1}>{title}</h1>
        //                 <div className="m-6" />
        //                 {showDescription ? <p className="max-lines-3 lg:max-lines-4">{description}</p> : <></>}
        //                 <div className="m-6" />
        // <div className="flex items-center">
        //     <Button
        //         page={watchLink}
        //         label="Play"
        //     />
        //     <div className="m-2" />
        //     {
        //         id ? <OutlineButton
        //             page={`/vod/movie/${id}`}
        //             label="Info"
        //         />
        //             : <></>
        //     }
        //     {isPlaying ? <div className="ml-3 cursor-pointer">
        //         {
        //             isMuted
        //                 ? <img onClick={() => { setIsMuted(!isMuted) }} src={speaker} alt="speaker_icon" />
        //                 : <img onClick={() => { setIsMuted(!isMuted) }} src={mute} alt="mute_icon" />
        //         }
        //     </div> : <></>}
        // </div>
        //             </div>
        //         </div>
        //         {children}
        //         <BannerBackground
        // playVideo={playVideo}
        // isPlaying={isPlaying}
        // bgImage={bgImage}
        // video={trailer}
        // muted={isMuted}
        //             id={id}
        //         />
        //     </div>
        // </div>
    )
}

const styles = {
    banner: `text-[#fff] bg-[#000] pt-[100px] lg:pt-0 h-[100vh] flex flex-col items-center lg:mt-[30px] -mt-[60px]`,
    bannerWrapper: `z-10 w-screen max-w-[1900px] m-auto`,
    bannerContent: `max-w-2xl p-5`,
    bannerBgImg: `absolute top-0 left-0 h-full w-full object-cover`,
    h1: `text-3xl lg:text-5xl font-medium`,
}

export default Banner