import axios from "axios"
import { toast } from "wc-toast"
import { ALT_BASE_URL, VOD_BASE_URL } from "../../config/apis.config"
import { COOKIES } from "../../constants/global.const"
import { FEATURED_PAGE_RAILS, MOVIE_PAGE_RAILS } from "../../constants/railsToShow"
import { getKeyByValue } from "../../utils/global.util"
import getGenreId from "../../utils/vod/getGenreId.util"
import { setGenreMoviesLoading, setSearchResultsLoading, setVideoLoading, setVODDetailsLoading } from "../slices/ui.slice"
import { refreshToken } from "./auth"

const userInfoCookie = COOKIES.get("user_info")
const { access_token, operator_uid, user_id } = userInfoCookie || {}

export const getPackages = async () => {
    try {

        let packageIds = []
        let packageIdsString = ""

        await refreshToken()

        const packages = await axios.get(
            ALT_BASE_URL + `/api/client/v1/${operator_uid}/users/${user_id}/packages?device_class=desktop`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            }
        )

        if (packages.data.status === "ok") {
            [...packages.data.data].forEach((item) => {
                return packageIds.push(item.id);
            })
            packageIdsString = packageIds.join(',')
        }

        return {
            packages: packages.data.data,
            packageIds: packageIds,
            packageIdsString: packageIdsString,
        }
    } catch (e) {
        console.error("get packages:", e.message)
    }
}

export const getCategories = async () => {
    try {

        let { packageIdsString } = await getPackages()
        let categoryIds = []
        let categoriesIdsString = ""

        const categories = await axios.get(
            ALT_BASE_URL + `/api/client/v2/${operator_uid}/categories/vod?packages=${packageIdsString}`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            }
        )

        categoryIds = categories.data.data.map((item) => {
            return item.id;
        })

        categoriesIdsString = categoryIds.join(',')

        return {
            categories: categories.data.data,
            categoryIds,
            categoriesIdsString
        }

    } catch (e) {
        console.error('get categories:', e.message)
    }
}

export const getCategoryMovies = async (dispatch) => {
    try {

        await refreshToken(dispatch || undefined)

        // if (window.location.pathname === "/movies") console.log(MOVIE_PAGE_RAILS)
        // if (window.location.pathname === "/home") console.log(FEATURED_PAGE_RAILS)

        let { categories, categoriesIdsString } = await getCategories()
        let { packageIds } = await getPackages()
        let categoryNamesAndId = {}
        let vods = []

        categories.map((item) => {
            return (categoryNamesAndId[item.uid] = item.id);
        })

        let categoryMovies = await axios.get(
            ALT_BASE_URL + `/api/client/v1/${operator_uid}/categories/vod/content?packages=${packageIds}&categories=${categoriesIdsString}`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            }
        )

        for (let a = 0; a < categoryMovies.data.data.length; a++) {
            const element = categoryMovies.data.data[a];
            const vodContent = {}

            console.log(categoryNamesAndId)

            vodContent.title = getKeyByValue(categoryNamesAndId, element.id)
            vodContent.content = element.content
            vods.push(vodContent)
        }

        let pageCategories = []

        for (let b = 0; b < vods.length; b++) {
            const element = vods[b];
            const title_ = element.title.toUpperCase().replaceAll("_", " ")

            if (window.location.pathname === "/movies") {
                if (MOVIE_PAGE_RAILS.includes(title_)) pageCategories.push(element)
            }

            if (window.location.pathname === "/home") {
                if (FEATURED_PAGE_RAILS.includes(title_)) pageCategories.push(element)
            }
        }

        // console.log(vods)

        return pageCategories

    } catch (e) {
        console.error("get category movies:", e.message)
        // toast.error(e.message)
    }
}

export const getGenreMovies = async (activeMovieGenre, dispatch) => {
    try {

        if (activeMovieGenre === "All") return []

        dispatch(setGenreMoviesLoading(true))

        let { categories, categoriesIdsString } = await getCategories()
        let { packageIds } = await getPackages()
        let categoryNamesAndId = {}
        let genreId = getGenreId(await getGenres(), activeMovieGenre)

        categories.map((item) => {
            return (categoryNamesAndId[item.uid] = item.id);
        })

        let categoryMovies = await axios.get(
            ALT_BASE_URL + `/api/client/v1/${operator_uid}/categories/vod/content?packages=${packageIds}&categories=${categoriesIdsString}&genres=${genreId}`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            }
        )

        let genreMovies = []

        for (let a = 0; a < categoryMovies.data.data.length; a++) {
            const element = categoryMovies.data.data[a];
            for (let b = 0; b < element.content.length; b++) {
                const _element = element.content[b]
                genreMovies.push(_element)
            }
        }

        dispatch(setGenreMoviesLoading(false))
        return genreMovies

    } catch (e) {
        console.error("get genre movies:", e.message)
        dispatch(setGenreMoviesLoading(false))
        // toast.error(e.message)
    }
}

export const getSeriesDetails = async (id, dispatch) => {
    try {

        if (!id) return {}

        await refreshToken(dispatch || undefined)

        if (dispatch) dispatch(setVODDetailsLoading(true))

        const seriesDetailsRes = await axios.get(
            ALT_BASE_URL + `/api/client/v1/${operator_uid}/series/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            }
        )

        if (dispatch) dispatch(setVODDetailsLoading(false))

        return seriesDetailsRes.data.data

    } catch (e) {
        console.error("get series details:", e.message)
        dispatch(setVODDetailsLoading(false))
        toast.error(e.message)
    }
}

export const getEpisodeInfo = async (id) => {
    try {

        const response = await axios.get(
            ALT_BASE_URL + `/api/client/v1/${operator_uid}/episodes/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            }
        )

        return response.data.data
    } catch (e) {
        console.warn(e.message)
    }
}

export const getSearchResults = async (query, dispatch) => {

    if (!query) return {}

    try {

        dispatch(setSearchResultsLoading(true))

        await refreshToken(dispatch)

        const { packageIdsString } = await getPackages()
        const sanitizedQuery = query.replace(/[^a-zA-Z ]/g, "")


        const searchResultsResponse = await axios.get(
            ALT_BASE_URL + `/api/client/v1/${operator_uid}/search/movies/${sanitizedQuery}?translation=hr&packages=${packageIdsString}`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            }
        )

        dispatch(setSearchResultsLoading(false))

        return searchResultsResponse.data.data

    } catch (e) {
        console.error("get search results:", e.message)
        dispatch(setSearchResultsLoading(false))
        // toast.error(e.message)
    }
}

export const getMovieDetails = async (id, type, dispatch) => {
    try {

        if (!id) return {}

        if (dispatch) dispatch(setVODDetailsLoading(true))

        await refreshToken(dispatch || undefined)

        const movieDetailsRes = await axios.get(
            ALT_BASE_URL + `/api/client/v2/${operator_uid}/movies?movies=${id.toString()}`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            }
        )

        if (dispatch) dispatch(setVODDetailsLoading(false))

        return movieDetailsRes.data.data[0]

    } catch (e) {
        console.error("get movie details:", e.message)
        dispatch(setVODDetailsLoading(false))
        toast.error(e.message)
    }
}

export const getGenres = async () => {
    try {
        const genreRes = await axios.get(
            ALT_BASE_URL + `/api/client/v1/${operator_uid}/genres`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            }
        )

        return genreRes.data.data
    } catch (e) {
        console.error("get genres:", e.message)
    }
}

export const getVideo = async (id, type, dispatch) => {
    try {

        dispatch(setVideoLoading(true))

        await refreshToken(dispatch)

        let url

        if (type === "movie") url = ALT_BASE_URL + `/api/client/v1/${operator_uid}/users/${user_id}/vod/movies/${id}`
        if (type === "series") url = ALT_BASE_URL + `/api/client/v1/${operator_uid}/users/${user_id}/vod/episodes/${id}`
        if (type === "live") url = ALT_BASE_URL + `/api/client/v1/${operator_uid}/users/${user_id}/live/channels/${id}`

        const videoRes = await axios.get(url,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            }
        )

        dispatch(setVideoLoading(false))
        return videoRes.data.data

    } catch (e) {
        dispatch(setVideoLoading(false))
        toast.error("Unable to load video")
        console.error("get video:", e.message)
    }
}

export const getTrailer = async (id, type = "movie") => {
    // console.log("get trailer", id, type)
    try {

        const trailerRes = await axios.get(
            ALT_BASE_URL + `/api/client/v1/${operator_uid}/users/${user_id}/vod/trailers/movies/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            }
        )

        return trailerRes.data.data.url

    } catch (e) {
        console.error("get trailer:", e.message)
    }
}

export const getBannerContent = async () => {
    try {

        const bannerContentRes = await axios.get(
            ALT_BASE_URL + `/api/client/v1/${operator_uid}/banners?translation=en&accessKey=WkVjNWNscFhORDBLCg==`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            }
        )

        return bannerContentRes.data.data[0]

    } catch (e) {
        // toast.error(e.message)
        console.error("get banner content:", e.message)
    }
}

export const getSimilarMovies = async (id, type, dispatch) => {
    try {

        let similarMoviesRes = await axios.get(
            ALT_BASE_URL + `/api/client/v1/${operator_uid}/vod/${type}/${id}/related`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            }
        )

        return similarMoviesRes.data.data

    } catch (e) {
        console.error("get similar movies: ", e.message)
    }
}

export const getWatchlist = async () => {
    try {

        await refreshToken()

        const watchlistRes = await axios.get(
            ALT_BASE_URL + `/api/client/v1/${operator_uid}/users/${user_id}/my_content`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                    'Content-Type': 'application/json'
                }
            }
        )

        return watchlistRes.data.data.movie_bookmarks

    } catch (e) {
        toast.error("Unable to get your watchlist")
        console.error("get watchlist:", e.message)
    }
}

export const updateWatchlist = async (id, type, lengthWatchedInMs = 0, hideAlert = false) => {

    try {

        if (!id) return

        let url

        if (type === 'series') url = ALT_BASE_URL + `/api/client/v1/${operator_uid}/users/${user_id}/bookmarks/episodes/${id}`
        if (type === 'movie') url = ALT_BASE_URL + `/api/client/v1/${operator_uid}/users/${user_id}/bookmarks/movies/${id}/${id}`

        const updateWatchlistRes = await axios.put(
            url, {
            "time": lengthWatchedInMs,
            "name": id,
        },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                    'Content-Type': 'application/json'
                }
            }
        )

        if (!hideAlert)
            if (updateWatchlistRes.data.data.updated)
                toast.success("Added to watchlist")

    } catch (e) {
        // toast.error(e.message)
        console.error("update watchlist:", e.message)
    }
}

export const getLengthWatched = async (id, _type) => {
    try {

        if (!id) return

        let url

        if (_type === 'series') url = ALT_BASE_URL + `/api/client/v1/${operator_uid}/users/${user_id}/bookmarks/episodes/${id}`
        if (_type === 'movie') url = ALT_BASE_URL + `/api/client/v1/${operator_uid}/users/${user_id}/bookmarks/movies/${id}`

        const getLengthWatchedRes = await axios.get(url,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            }
        )

        if (getLengthWatchedRes.data.data[0])
            return getLengthWatchedRes.data.data[getLengthWatchedRes.data.data.length - 1].time
        return 0

    } catch (e) {
        console.error(e.message)
        // return 0
    }
}

export const removeFromWatchlist = async (id, type) => {

    try {

        if (!id) return

        const removeFromWatchlistRes = await axios.delete(
            ALT_BASE_URL + `/api/client/v1/${operator_uid}/users/${user_id}/bookmarks/movies/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                },
            }
        )

        if (removeFromWatchlistRes.data.data.affectedRows > 0)
            toast.success("Removed from watchlist")

    } catch (e) {
        // toast.error(e.message)
        console.error("remove from watchlist:", e.message)
    }
}