import { useEffect, useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Link } from "react-router-dom"
import { getChannelInfo } from "../../../redux/functions/channels"
import { capitalizeString } from "../../../utils/global.util"
import getEPGInfo from "../../../utils/vod/getEPGInfo.util"
import getVODImage from "../../../utils/vod/getVODImage.util"

const LiveTVCard = ({ content, showTitles, maxLines, isGridItem, subtitle }) => {
    const [channelInfo, setChannelInfo] = useState({})
    const [epgInfo, setEPGInfo] = useState({ start: '00:00', end: '00:00', title: '' })

    useEffect(() => {
        const initFetchChannelInfo = async () => {
            setChannelInfo(await getChannelInfo(content.id))
        }

        const initSetDates = async () => {
            setEPGInfo(getEPGInfo(content.shows))
        }

        initSetDates()
        initFetchChannelInfo()
    }, [content])

    if (!channelInfo) return (<></>)

    return (
        <>
            <Link to={`/watch/live/` + channelInfo.uid + "?i=" + channelInfo.uid + "&t=" + epgInfo.title}>
                <div className={styles.card}>
                    {
                        channelInfo.image_stores
                            ? <div>
                                <LazyLoadImage
                                    className="rounded-md h-[100px] object-contain bg-[#121619]"
                                    src={getVODImage(channelInfo.image_stores[0].id)}
                                    alt={"poster of " + content.title}
                                />
                            </div>
                            : <></>
                    }

                    <div>
                        <p className="max-lines-1 text-sm mt-3 opacity-60">{capitalizeString(epgInfo.title.replace(/ *\([^)]*\) */g, ""))}</p>
                        <p className="text-sm">{epgInfo.start} - {epgInfo.end}</p>
                    </div>
                </div>
            </Link>
        </>
    )
}

const styles = {
    card: ``,
    gridCard: `w-full max-w-[120px] lg:max-w-[150px] m-3`,
}

export default LiveTVCard