export const FEATURED_PAGE_RAILS = [
    "TRENDING",
    "COMEDY",
    "FREE TO AIR CHANNELS",
    "TOP SEARCHES",
    "ACTION AND ADVENTURES",
    "MOST WATCHED CHANNELS",
    "ROMCOMS",
    "MOVING STORIES",
    "FAMILY AND KIDS",
    "FEEL GOOD MOVIES",
    "TWISTED DRAMA",
    "BEST OF EPICS",
    "HIDDEN GEMS"
]

export const MOVIE_PAGE_RAILS = [
    "DISCOVER",
    "INSPIRED BY TRUE STORIES",
    "AWARD NOMINATED STORIES",
    "STARRING EDDIE WATSON",
    "CRIME MOVIES",
    "MARRIAGE STORIES",
    "WHAT OTHERS ARE WATCHING",
    "WHEN MEN CHEAT",
    "MORE EPICS",
    "TRENDING",
]