import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCategoryMovies, getMovieDetails } from "../../redux/functions/vod"
import { AppContext } from "../../context"
import Banner from "../../components/Banner"
import Carousel from "../../components/Carousel"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import getVODImage from "../../utils/vod/getVODImage.util"
import GenreTabs from "../../components/GenreTabs"
import GenreMovies from "../../components/GenreMovies"
import getRandomIndexes from "../../utils/getRandomIndexes.util"
import SlidesCarousel from "../../components/SlidesCarousel"

const Movies = () => {
    const dispatch = useDispatch()

    // discover, inspired by true stories, award nominated, starring eddie watson, crime movies, marriage stories, what others are watching, when men cheat, more epics

    const { activeMovieGenre } = useSelector(state => state.ui)
    const { selectedFeatureSlide } = useContext(AppContext)

    const [categoryMovies, setCategoryMovies] = useState([])
    const [bannerDetails, setBannerDetails] = useState({})
    const [slides, setSlides] = useState([])

    useEffect(() => {
        const init = async () => {
            let _categoryMovies = await getCategoryMovies(dispatch)
            if (_categoryMovies)
                setCategoryMovies(_categoryMovies)
        }
        init()
    }, [])

    useEffect(() => {
        const initSetSlides = async () => {
            if (categoryMovies && categoryMovies.length > 0) {
                let slides_ = []
                let vodForSlides = categoryMovies[0].content
                let indexes = getRandomIndexes(vodForSlides)

                for (let a = 0; a < indexes.length; a++) {
                    const element = indexes[a]
                    let elementContent = vodForSlides[element]

                    if (elementContent && elementContent.type === "movie") {
                        let elementVODContent = await getMovieDetails(elementContent.id, "", dispatch)
                        slides_.push(elementVODContent)
                        // console.log('elementContent',)
                    }
                }

                setSlides(slides_)
            }
        }
        initSetSlides()
    }, [categoryMovies])

    //*keep useEffect(() => {
    //     const initSetBannerDetails = async () => {
    //         let content = await getBannerContent()
    //         setBannerDetails_(content, content.preview_image_id)
    //     }
    //     initSetBannerDetails()
    // }, [])

    useEffect(() => {
        if (slides[0]) {
            setBannerDetails_(slides[0], slides[0].images.PREVIEW || slides[0].image_store_id)
        }
    }, [slides, dispatch])

    useEffect(() => {
        const initSetSlides = async () => {
            if (selectedFeatureSlide && dispatch) {
                const slideDetails = await getMovieDetails(selectedFeatureSlide.id, "", dispatch)
                if (slideDetails.images)
                    setBannerDetails_(slideDetails, slideDetails.images.PREVIEW || slideDetails.image_store_id)
            }
        }
        initSetSlides()
    }, [selectedFeatureSlide, dispatch])

    const setBannerDetails_ = async (content, imageId) => {
        if (!content) return

        let x = {
            title: content.title,
            description: content.description,
            watchLink: `/watch/movie/${content.uid}?i=${content.id}&t=${content.title}`,
            bgImage: getVODImage(imageId),
            id: content.id,
        }
        setBannerDetails(x)
    }

    return (
        <>
            <Header />
            <wc-toast></wc-toast>
            <Banner content={bannerDetails} >
                <div className="absolute bottom-[20px] z-10 w-screen hidden lg:block">
                    <SlidesCarousel
                        maxLines={1}
                        items={slides}
                    />
                </div>
            </Banner>
            <GenreTabs />
            {activeMovieGenre !== "All"
                ? <GenreMovies />
                : <div>
                    {
                        categoryMovies.map((category, i) => {
                            return (
                                <Carousel
                                    // showTitles
                                    key={i + Date.now()}
                                    title={category.title}
                                    items={category.content}
                                />
                            )
                        })
                    }
                </div>}
            <Footer />
        </>
    )
}

export default Movies