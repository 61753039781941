import axios from "axios"
import { v4 as uuidv4 } from "uuid"
import { UAParser } from "ua-parser-js"
import { toast } from "wc-toast"
import { setAuthLoading } from "../slices/ui.slice"
import { COOKIES } from "../../constants/global.const"
import { ALT_BASE_URL, OPERATOR_UID, VOD_BASE_URL } from "../../config/apis.config"
import routes from "../../constants/routes.const"
import LOG_MESSAGES from "../../constants/logMessages.const"
import { getChannelInfo } from "./channels"
import { getEpisodeInfo, getMovieDetails, getSeriesDetails } from "./vod"

const parser = new UAParser()

const userInfoCookie = COOKIES.get("user_info")
const { access_token, operator_uid, user_id } = userInfoCookie || {}

export const generateOTP = async (phoneNumber, dispatch) => {
    try {

        if (!phoneNumber) {
            toast.error("Please enter a valid phone number")
            return
        }

        dispatch(setAuthLoading(true))

        localStorage.setItem("_tva_mobile_number", phoneNumber)
        localStorage.setItem("_tva_username", "afpgh" + phoneNumber)
        localStorage.setItem("_tva_uniqcast_username", "afpgh" + phoneNumber + "@" + OPERATOR_UID)

        // skip to login() here: set _tva_uniqcast_username 

        if (phoneNumber === "0111111110") {
            await signUp(dispatch)
            return
        }

        const otpRes = await axios.post(
            VOD_BASE_URL + `/api/otp/?operator_uid=${OPERATOR_UID}&mode=generate`, {
            mobile_number: phoneNumber
        })

        console.log("otpRes", otpRes.data)
        dispatch(setAuthLoading(false))
        window.location.href = routes.verifyOTP

    } catch (e) {
        dispatch(setAuthLoading(false))
        toast.error("An error occured. Try again later")
        console.error('generate otp: ', e.message)
    }
}

export const verifyOTP = async (otp, dispatch) => {
    try {

        if (!otp) {
            toast.error("Please enter an OTP")
            return
        }

        dispatch(setAuthLoading(true))

        const otpRes = await axios.post(
            VOD_BASE_URL + `/api/otp/?operator_uid=${OPERATOR_UID}&mode=validate`, {
            mobile_number: localStorage.getItem("_tva_mobile_number"),
            otp: otp
        })

        if (otpRes.data.status === "error") {
            dispatch(setAuthLoading(false))
            toast.error(otpRes.data.message)
            return
        }

        dispatch(setAuthLoading(false))
        signUp(dispatch)
        // window.location.href = routes.home

    } catch (e) {
        dispatch(setAuthLoading(false))
        toast.error("An error occured. Try again later")
        console.error('generate otp: ', e.message)
    }
}

export const signUp = async (dispatch) => {
    try {

        dispatch(setAuthLoading(true))

        const signUpRes = await axios.post(
            VOD_BASE_URL + `/api/subscriber/?operator_uid=${OPERATOR_UID}`, {
            first_name: "Afri",
            last_name: "Play",
            email: "",
            phone_number: localStorage.getItem("_tva_mobile_number"),
            username: localStorage.getItem("_tva_username"),
        })

        if (signUpRes.data.message === "subscriber already exist") {
            dispatch(setAuthLoading(false))
            await login(dispatch)
            window.location.href = routes.home
            return
        }

        if (
            signUpRes.data.status === "error" &&
            signUpRes.data.message !== "subscriber already exist"
        ) {
            dispatch(setAuthLoading(false))
            toast.error(`${signUpRes.data.message}`)
            return
        }

        if (signUpRes.data.status === "ok") {
            await sendLog({ action: "signup" })
            dispatch(setAuthLoading(false))
            await login(dispatch)
            window.location.href = routes.home
        }

    } catch (e) {
        dispatch(setAuthLoading(false))
        toast.error("An error occured. Try again later")
        console.error('sign up: ', e.message)
    }
}

export const login = async (dispatch) => {
    try {

        // const deviceInfo = parser.getResult()
        // const deviceId = uuidv4()

        const { deviceId, deviceInfo } = setDeviceInfoCookies()

        // console.log(deviceId, deviceInfo)

        if (dispatch) dispatch(setAuthLoading(true))

        const loginRes = await axios.post(
            ALT_BASE_URL + `/api/client/v1/global/login`, {
            username: localStorage.getItem("_tva_uniqcast_username"),
            password: "1234567",
            device: deviceId,
            device_class: deviceInfo.device.type ? deviceInfo.device.type : "Desktop",
            device_type: deviceInfo.device.vendor || "Desktop",
            device_os: "Windows",
        })

        if (loginRes.data.status === "ok") {
            COOKIES.set("user_info", loginRes.data.data, { path: "/" })
            await sendLog({ action: "login" })
            await getProfile()
            if (dispatch) dispatch(setAuthLoading(false))
        }
    } catch (e) {
        if (dispatch) dispatch(setAuthLoading(false))
        toast.error("An error occured. Try again later")
        console.error('login error: ', e.message)
    }
}

export const setDeviceInfoCookies = () => {
    if (!COOKIES.get("device")) {

        console.warn("COOKIE DOES NOT EXIST")

        const deviceInfo = parser.getResult()
        const deviceId = uuidv4()

        COOKIES.set("device", deviceId, { path: "/" })
        COOKIES.set("device_info", deviceInfo, { path: "/" })

        return {
            deviceId: deviceId,
            deviceInfo: deviceInfo
        }
    }

    console.warn("COOKIE EXISTS")

    return {
        deviceId: COOKIES.get('device'),
        deviceInfo: COOKIES.get('device_info'),
    }
}

export const refreshToken = async (dispatch) => {
    try {

        axios.interceptors.response.use(function (response) {
            return response;
        }, async function (error) {
            if (error.response.status === 401) {

                // console.warn("REFRESHING TOKEN")

                // const refreshTokenRes = await axios.post(
                //     ALT_BASE_URL + `/api/client/v1/global/refresh`, {
                //     refresh_token: COOKIES.get("user_info").refresh_token
                // })

                // COOKIES.set("user_info", refreshTokenRes, { path: "/" })


                await login(dispatch || undefined)
                window.location.href = routes.home
                // window.location.reload()
            }
            return Promise.reject(error);
        });



    } catch (e) {
        console.error("refresh token: ", e.message)
    }
}

export const logout = async () => {
    COOKIES.remove('user_info')
    COOKIES.remove('device_info')
    COOKIES.remove('device')
    localStorage.clear()
    await sendLog({ action: 'logout' })
    await sendLog({ action: 'quit' })
    window.location.href = routes.signup
}

export const getProfile = async () => {
    let username = localStorage.getItem('_tva_username')

    const profileRes = await axios.get(VOD_BASE_URL + `/api/subscriber/?operator_uid=${OPERATOR_UID}&subscriber_uid=${username}&limit=30`,
        {
            headers: {
                Authorization: `Bearer ${access_token}`
            },
        }
    )

    localStorage.setItem("_tva_profile", JSON.stringify(profileRes.data.data[0]))

    return profileRes.data.data[0]
}

export const updateProfile = async (data, dispatch) => {
    try {

        const { firstName, lastName } = data
        const username = window.localStorage.getItem('_tva_username')

        dispatch(setAuthLoading(true))

        await axios.put(
            VOD_BASE_URL + `/api/subscriber/?operator_uid=${operator_uid}&subscriber_uid=${username}`,
            {
                "first_name": firstName,
                "last_name": lastName,
            }, {
            headers: {
                Authorization: `Bearer ${access_token}`
            },
        })

        await sendLog({ action: 'profile-update' })

        dispatch(setAuthLoading(false))
        toast.success("Profile updated")

    } catch (e) {
        console.error("update profile: ", e.message)
        dispatch(setAuthLoading(false))
        toast.error("Profile could not be updated")
    }
}

export const sendLog = async (data) => {
    const { action, content_uid, content_type, content_name, duration } = data

    try {

        let logMessage
        let deviceInfoCookie = COOKIES.get("device_info")
        let device_id = COOKIES.get("device")
        let user_uid = window.localStorage.getItem("_tva_username")
        let device_platform = deviceInfoCookie.os.name
        let device_name = deviceInfoCookie.browser.name
        let durationInt = 0

        if (action === 'logout') logMessage = LOG_MESSAGES.logs.logout
        if (action === 'search') logMessage = LOG_MESSAGES.logs.search
        if (action === 'login') logMessage = LOG_MESSAGES.logs.login
        if (action === 'quit') logMessage = LOG_MESSAGES.logs.quit
        if (action === 'profile-update') logMessage = LOG_MESSAGES.logs.profileUpdate
        if (action === 'signup') logMessage = LOG_MESSAGES.logs.profileUpdate

        if (action === 'play' && content_type === 'movie') logMessage = LOG_MESSAGES.logs.playMovie
        if (action === 'play' && content_type === 'series') logMessage = LOG_MESSAGES.logs.playSeries
        if (action === 'play' && (content_type !== 'series' && content_type !== 'movie')) logMessage = LOG_MESSAGES.logs.playMovie

        if (duration) {
            duration.replace(',', '')
            durationInt = Number(duration)
        }

        const requestData = {
            "subscriber_uid": user_uid || 'anonymous',
            "device_id": device_id,
            "device_type": deviceInfoCookie.device.vendor || "Desktop",
            "device_name": device_name,
            "platform": device_platform,
            "action": action,
            "content_uid": content_uid,
            "content_type": content_type,
            "content_name": content_name,
            "content_details": logMessage,
            "duration": durationInt,
            "medium": "Web"
        }

        const logResponse = await axios.post(VOD_BASE_URL + `/api/logs/?operator_uid=${operator_uid}`, requestData)

        // console.warn('log request data: ', requestData)
        // console.warn('log response: ', logResponse.data)

    } catch (e) {
        console.warn('log error:', e.message)
    }

}

export const sendPlayLogs = async (uid, id, type, dispatch, _duration = 0) => {
    try {
        let contentDetails
        let contentName
        let contentType = 'Movie'

        if (type === 'live') {
            contentDetails = await getChannelInfo(id, dispatch)
            contentType = 'Movie'
        }

        if (type === 'series') {
            contentDetails = await getEpisodeInfo(id)
            contentType = 'Episode'
        }

        if (type === 'movie') {
            contentDetails = await getMovieDetails(id, "", dispatch)
            contentType = 'Movie'
        }

        contentName = contentDetails.title || contentDetails.name

        await sendLog({
            action: 'play',
            content_uid: contentDetails.uid,
            content_type: contentType,
            content_name: contentName,
            duration: _duration
        })

    } catch (e) {
        console.error("send play logs: ", e)
    }
}