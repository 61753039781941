import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { verifyOTP } from "../../redux/functions/auth"
import OtpInput from 'react-otp-input';
import Button from "../../components/buttons/Button"
import Header from "../../components/Header"
import authStyle from "../../styles/auth.style";
import Footer from "../../components/Footer";

const Verify = () => {
    const dispatch = useDispatch()
    const { authLoading } = useSelector(state => state.ui)
    const [otp, setOTP] = useState("")

    return (
        <>
            <Header />
            <wc-toast></wc-toast>
            <main className={authStyle.main}>
                <div className={authStyle.authContainer}>
                    <h3 className={authStyle.h3}>Verify OTP</h3>
                    <div className="mt-5 flex flex-col">
                        <div className="flex flex-col mb-3 text-[#000]">
                            <OtpInput
                                value={otp}
                                onChange={e => setOTP(e)}
                                numInputs={6}
                                // separator={<span className="">-</span>}
                                inputStyle="outline-none border border-borderGrey w-full min-w-[30px] lg:min-w-[40px] mr-1"
                            />
                        </div>
                        {
                            authLoading
                                ? <p>loading...</p>
                                : <Button
                                    action={() => verifyOTP(otp, dispatch)}
                                    label="Done"
                                />
                        }
                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
}

export default Verify