const LOG_MESSAGES = {
    AUTH: {
        wrongOTP: 'Invalid OTP code',
        invalidMobileNumber: 'Invalid Mobile Number',
        invalidEmail: 'Invalid Mobile Number',
    },
    VERIFICATION: {
        invalidNetwork: 'Please continue with a valid network',
    },
    errorOccured: 'An error occured. Try again',
    logs: {
        login: "User logged in",
        signup: "User signed up",
        logout: "User logged out",
        playMovie: "User played movie",
        playSeries: "User played series",
        // visitLandingGlobal: "User visited global instance",
        // visitLandingGH: 'User visited Ghana instance',
        // visitLandingNG: 'User visited Nigeria instance',
        search: "User searched for content",
        quit: "User closed web client",
        profileUpdate: "User updated profile",
    }
}

export default LOG_MESSAGES