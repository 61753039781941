import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { generateOTP } from "../../redux/functions/auth"
import Button from "../../components/buttons/Button"
import Header from "../../components/Header"
import authStyle from "../../styles/auth.style"
import Footer from "../../components/Footer"

const Signup = () => {
    const dispatch = useDispatch()
    const { authLoading } = useSelector(state => state.ui)
    const [phoneNumber, setPhoneNumber] = useState("")
    const [password, setPassword] = useState("")

    useEffect(() => {
        const savedNumber = localStorage.getItem("_tva_mobile_number")
        if (!savedNumber) return
        setPhoneNumber(savedNumber)
    }, [])

    const handlePhoneNumberInput = e => {
        const text = e.target.value
        const limit = 10;
        if (isNaN(Number(text))) return
        setPhoneNumber(text.slice(0, limit))
    }

    return (
        <>
            <Header />
            <wc-toast></wc-toast>
            <main className={authStyle.main}>
                <div className={authStyle.authContainer}>
                    <h3 className={authStyle.h3}>Phone number</h3>
                    <div className="mt-5 flex flex-col">
                        <div className="flex flex-col mb-3">
                            <input
                                value={phoneNumber}
                                onChange={handlePhoneNumberInput}
                                placeholder="Enter phone number"
                                className={authStyle.input}
                            />
                        </div>
                        {
                            authLoading
                                ? <p>loading...</p>
                                : <Button
                                    action={() => generateOTP(phoneNumber, dispatch)}
                                    label="Continue"
                                />
                        }
                        {/* <p className="max-w-[210px] text-center leading-[9px] text-[12px] m-auto mt-5">
                            By siginging in, you agree to Kairos Play
                            <span>
                                <Link to="/">
                                    <span className="text-brand"> Terms of use.</span>
                                </Link>
                            </span>
                        </p> */}
                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
}

export default Signup