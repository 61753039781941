import React, { useState } from 'react';

export const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
    const [selectedFeatureSlide, setSelectedFeatureSlide] = useState({})

    return <AppContext.Provider value={{
        selectedFeatureSlide, setSelectedFeatureSlide
    }}>
        {children}
    </AppContext.Provider>
}
