import { sliderConfig } from "../../config/slider.config"
import Slider from "react-slick"
import SlideCard from "../Cards/SlideCard"

const SlidesCarousel = ({ items = [] }) => {
    if (items.length < 1) return (<></>)

    return (
        <>
            {/* <div className="max-w-[1900px] px-5 py-[30px] pb-[10px] m-auto hidden lg:block"> */}

            <div className="">
                <div className="ml-3">
                    <Slider {...sliderConfig(7)}>
                        {
                            items.map((item, i) => {
                                return (
                                    <SlideCard
                                        key={i}
                                        content={item}
                                        showTitles={false}
                                    />
                                )
                            })
                        }
                    </Slider>
                </div>
            </div>
        </>
    )
}

export default SlidesCarousel