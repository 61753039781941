const routes = {
    login: "/auth/login",
    signup: "/auth/mobile",
    verifyOTP: "/auth/verify",
    movies: "/movies",
    livetv: "/live-tv",
    account: "/account",
    search: "/search",
    home: "/home",
    policy: "/policy",
    // policy: "/policy/index.html",
    landing: "/",
}

export default routes