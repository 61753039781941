import { LazyLoadImage } from "react-lazy-load-image-component"
import { Link } from "react-router-dom"
import { capitalizeString } from "../../../utils/global.util"
import getVODImage from "../../../utils/vod/getVODImage.util"
import play from "../../../assets/icons/play-white.svg"

const Card = ({ content, showTitles, type, maxLines, isGridItem, subtitle }) => {
    return (
        <>
            <Link to={`/vod/` + content.type + "/" + content.id}>
                <div className={isGridItem ? styles.gridCard : styles.card}>
                    {
                        content.image_id
                            ? <div>
                                <LazyLoadImage
                                    src={getVODImage(content.image_id)}
                                    alt={"poster of " + content.title}
                                    width="100%"
                                    className="rounded-md"
                                    placeholder={<div className="w-full rounded-md h-[10px] bg-[#0d043d]"></div>}
                                />
                            </div>
                            : <></>
                    }

                    {showTitles ? <div>
                        <p className={`max-lines-${maxLines} leading-7 mt-3`}>{capitalizeString(content.title || "")}</p>
                        <p className="text-sm mt-1 opacity-60">{subtitle}</p>
                    </div> : <></>}

                    <div className="card-overlay-container absolute flex-col bottom-[-500px] left-0 w-full h-full bg-brand opacity-80 flex items-center justify-center transition-all">
                        <p className="lg:text-xl mb-2 max-lines-1 px-3 text-sm">{content.title}</p>
                        <img src={play} width="50" className="hover:scale-110 hover:opacity-100 opacity-75 transition-all" alt="play_button" />
                    </div>
                </div>
            </Link>
        </>
    )
}

const styles = {
    gridCard: `card m-1 w-full relative overflow-hidden max-w-[120px] mt-10 -mb-5 lg:max-w-[190px]`,
    card: `card lg:scale-90 relative overflow-hidden`
}

export default Card