import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { PROFILE_TAB_ITEMS } from "../../constants/tabs"
import { getProfile, updateProfile } from "../../redux/functions/auth"

const Profile = ({ active }) => {
    const dispatch = useDispatch()
    const { authLoading } = useSelector(state => state.ui)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')

    useEffect(() => {
        const initGetProfileInfo = async () => {
            let _profileInfo = await getProfile()

            setFirstName(_profileInfo.first_name)
            setLastName(_profileInfo.last_name)
        }
        initGetProfileInfo()
    }, [])

    const onUpdateClick = async () => {
        await updateProfile({ firstName, lastName }, dispatch)
    }

    if (active === PROFILE_TAB_ITEMS[2].value) return (
        <div className="p-5">
            <div className="p-3 rounded-md bg-[#D9D9D9] flex flex-col items-center w-full max-w-[300px]">
                <div className="flex items-center flex-col lg:flex-row justify-between mb-5">
                    <input value={firstName} onChange={e => setFirstName(e.target.value)} placeholder="First name" className={styles.input} />
                    <input value={lastName} onChange={e => setLastName(e.target.value)} placeholder="Last name" className={styles.input} />
                </div>
                <button className="bg-brand cursor-pointer px-3 font-[500] rounded-md" onClick={onUpdateClick}>
                    {authLoading ? "Saving..." : "Save"}
                </button>
            </div>
        </div>
    )

    return (<></>)
}

const styles = {
    input: `w-full text-black outline-none px-3 bg-[transparent] border-b border-brand mr-1 text-sm mb-5 lg:mb-0`,
}

export default Profile