import { logout } from "../redux/functions/auth"

export const NAV_LINKS = [

]

export const AUTHORIZED_NAV_LINKS = [
    {
        name: "Featured",
        link: "/home"
    }, {
        name: "Movies",
        link: "/movies"
    },
    {
        name: "Live TV",
        link: "/live-tv"
    }
]
