// import { useEffect, useState } from "react"
// import ReactPlayer from "react-player"

import { useEffect, useState } from "react"
import ReactPlayer from "react-player"

const BannerBackground = (props) => {
    const { bgImage, videoMuted, playVideo, video, isPlaying } = props

    const [isPlaying_, setIsPlaying_] = useState(isPlaying)
    const [video_, setVideo_] = useState(video)
    const [playVideo_, setPlayVideo_] = useState(playVideo)

    // console.log(props)

    useEffect(() => {
        setPlayVideo_(playVideo)
        setVideo_(video)
        setIsPlaying_(isPlaying_)
    }, [playVideo, video, isPlaying])

    useEffect(() => {
        const handleScroll = (event) => {
            if (window.scrollY > 150) setIsPlaying_(!isPlaying_)
            if (window.scrollY < 150) setIsPlaying_(true)
        }
        window.addEventListener("scroll", handleScroll)
        return () => { window.removeEventListener("scroll", handleScroll) }
    }, [])


    return (
        <div className="h-full w-full absolute top-0 left-0">
            {
                playVideo_ && video_
                    ? <div className="w-full h-full">
                        <ReactPlayer
                            height='100%'
                            width='100%'
                            url={video_}
                            playing={isPlaying_}
                            muted={videoMuted}
                            autoPlay={true}
                            controls={false}
                        />
                    </div>
                    : <img className="banner-overlay w-full h-full object-cover" src={bgImage} alt="" />
            }
        </div>
    )



    // console.log(playVideo, isPlaying, bgImage)

    // console.log("video", video)
    // useEffect(() => {
    //     const initGetTrailer = async () => {
    //         if (playVideo)
    //             console.log(await getTrailer(id, ""))
    //     }
    //     initGetTrailer()
    // }, [playVideo])

    // useEffect(() => {
    //     const handleScroll = (event) => {
    //         if (window.scrollY > 150) setIsPlaying_(!isPlaying_)
    //         if (window.scrollY < 150) setIsPlaying_(true)
    //     }
    //     window.addEventListener("scroll", handleScroll)
    //     return () => { window.removeEventListener("scroll", handleScroll) }
    // }, [])

    // if (playVideo && isPlaying) return (
    //     // <div className="h-screen">
    //     <ReactPlayer
    //         height='100%'
    //         width='100%'
    //         className={styles.videoBannerBgImg}
    //         url={video}
    //         playing={isPlaying_}
    //         muted={muted}
    //         autoPlay={true}
    //         controls={false}
    //     />
    //     // </div>
    // )

    // else return (
    //     <div className="">
    //         <img className={styles.bannerBgImg} src={bgImage} alt="" />
    //         {/* <div className="banner-gradient movies-gradient" /> */}
    //     </div>
    // )
}

const styles = {
    // bannerBgImg: `banner-overlay absolute top-0 left-0 h-full w-full object-cover`,
    // videoBannerBgImg: `banner-overlay absolute -mt-[100px] top-0 left-0 lg:h-screen h-[10vh] w-full object-cover`,
    // bannerBgImg: `banner-overlay w-screen h-full absolute top-0 -z-10 object-cover`,
    // bannerBgImg: `banner-overlay absolute h-full w-full top-0 left-0 -z-10 object-cover`,
    // videoBannerBgImg: ``,
    // bannerBgImg: `banner-overlay absolute top-0 left-0 h-[70%] lg:h-[100vh] w-full object-cover`,
    // videoBannerBgImg: `react-player absolute top-0 left-0 h-full w-full object-cover`,
}

export default BannerBackground