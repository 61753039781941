import { useContext } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { AppContext } from "../../../context"
import getVODImage from "../../../utils/vod/getVODImage.util"

const SlideCard = ({ content, isGridItem }) => {
    const { setSelectedFeatureSlide, selectedFeatureSlide } = useContext(AppContext)

    if (!content) return (<></>)

    const imgSource = content.images.PREVIEW || content.image_store_id || ""

    return (
        <>
            <div
                onClick={() => setSelectedFeatureSlide(content)}
                className={selectedFeatureSlide === content ? styles.active : styles.card}
            >
                {
                    imgSource
                        ? <div className="h-full">
                            <LazyLoadImage
                                className="rounded-md w-full h-full object-cover"
                                src={getVODImage(imgSource)}
                                alt={"poster of " + content.title}
                            />
                        </div>
                        : <></>
                }

                {/* {showTitles ? <div>
                        <p className={`max-lines-${maxLines} leading-7 mt-3`}>{capitalizeString(content.title || "")}</p>
                        <p className="text-sm mt-1 opacity-60">{subtitle}</p>
                    </div> : <></>} */}
            </div>
        </>
    )
}

const styles = {
    card: `h-[10px] p-1 rounded-md w-full h-[110px] cursor-pointer`,
    active: `h-[10px] p-1 rounded-md w-full h-[110px] cursor-pointer grayscale`,
    // active: `grayscale h-[10px] p-1 bg-[red] rounded-md w-full h-[150px] cursor-pointer`,
}

export default SlideCard