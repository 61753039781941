import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { getChannelCategories, getChannelInfo, getChannelEPGInfo } from "../../redux/functions/channels"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import LiveTVBanner from "../../components/LiveTVBanner"
import LiveTVCarousel from "../../components/LiveTVCarousel"

const LiveTV = () => {
    const dispatch = useDispatch()
    const [bannerDetails, setBannerDetails] = useState({})
    const [EPGs, setEPGs] = useState([])
    const [channelCategories, setChannelCategories] = useState([])
    const [bannerContent, setBannerContent] = useState("")

    useEffect(() => {
        const getBannerInfo = async () => {
            setBannerContent(await getChannelInfo("testchannel", dispatch))
        }

        getBannerInfo()
    }, [])

    // useEffect(() => {
    //     const initSetBannerDetails = async () => {
    //         let content = await getBannerContent()
    //         let x = {
    //             title: content.title,
    //             description: content.description,
    //             watchLink: `/watch/movie/${content.uid}`,
    //             bgImage: getVODImage(content.preview_image_id),
    //             video: "",
    //             id: content.id,
    //         }
    //         setBannerDetails(x)
    //     }

    //     initSetBannerDetails()
    // }, [])

    useEffect(() => {
        const getAllChanelsIDs = async () => {
            const channelIDs = []
            const _channelCategories = await getChannelCategories(dispatch)

            if (_channelCategories) {
                setChannelCategories(_channelCategories)

                for (let i = 0; i < _channelCategories.length; i++) {
                    const element = _channelCategories[i];
                    let channels = element.channels

                    for (let j = 0; j < channels.length; j++) {
                        channelIDs.push(channels[j].id)
                    }
                }

                setEPGs(await getChannelEPGInfo(channelIDs.toString()))
            }
        }

        getAllChanelsIDs()
    }, [])

    return (
        <>
            <Header />
            <wc-toast></wc-toast>
            <LiveTVBanner content={bannerContent || {}} />
            {
                channelCategories
                    ? <div>
                        {
                            channelCategories.map((channel, index) => {
                                return (
                                    <div key={channel.id + index}>
                                        <LiveTVCarousel
                                            channel={channel}
                                            items={EPGs}
                                            title={channel.name}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                    : <></>
            }
            <Footer />
        </>
    )
}

export default LiveTV