import { Link } from "react-router-dom"

const Button = ({ label, action, page }) => {
    if (action) return (
        <button onClick={action} className={styles.btn}>
            {label}
        </button>
    )

    if (page) return (
        <Link className={styles.btn} to={page}>
            {label}
        </Link>
    )

    return <></>
}

const styles = {
    btn: `bg-secondary text-black font-medium border-2 border-secondary text-sm active:scale-90 hover:opacity-70 transition-all whitespace-nowrap flex items-center justify-center py-[10px] px-4 rounded-md`
}

export default Button