import { useEffect, useState } from "react"
import { getCategoryMovies, getMovieDetails } from "../../redux/functions/vod"
import { useDispatch } from "react-redux"
import Banner from "../../components/Banner"
import Carousel from "../../components/Carousel"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import getVODImage from "../../utils/vod/getVODImage.util"
import getRandomIndexes from "../../utils/getRandomIndexes.util"

const Home = () => {
    const dispatch = useDispatch()

    // trending, comedy, free to air channels, top searches, action&adventure, most watched channels, romcoms, moving stories, family&kids, feel good movies, twisted drama, best of epics, hidden gems

    const [categoryMovies, setCategoryMovies] = useState([])
    const [bannerDetails, setBannerDetails] = useState({})
    const [slides, setSlides] = useState([])

    useEffect(() => {
        const init = async () => {
            if (await getCategoryMovies())
                setCategoryMovies(await getCategoryMovies())
        }
        init()
    }, [])

    useEffect(() => {
        const initSetSlides = async () => {
            if (categoryMovies && categoryMovies.length > 0) {
                let slides_ = []
                let vodForSlides = categoryMovies[7].content
                let indexes = getRandomIndexes(vodForSlides)

                for (let a = 0; a < indexes.length; a++) {
                    const element = indexes[a]
                    let slideDetailsItem = vodForSlides[element]

                    if (slideDetailsItem && slideDetailsItem.type === "movie")
                        slides_.push(slideDetailsItem)
                }

                setSlides(slides_)
            }
        }
        initSetSlides()
    }, [categoryMovies])

    useEffect(() => {
        const initSetBannerContent = async () => {
            if (slides && slides.length > 0) {
                const slideDetails = await getMovieDetails(slides[0].id, "", dispatch)
                if (slideDetails)
                    setBannerDetails_(slideDetails, slideDetails.images.PREVIEW || slideDetails.image_store_id)
            }
        }
        initSetBannerContent()
    }, [slides, dispatch])

    const setBannerDetails_ = async (content, imageId) => {
        if (!content) return

        let x = {
            title: content.title,
            description: content.description,
            watchLink: `/watch/movie/${content.uid}?i=${content.id}&t=${content.title}`,
            bgImage: getVODImage(imageId),
            id: content.id,
            type: "movie",
        }

        // console.log(x)
        setBannerDetails(x)
    }

    return (
        <>
            <Header />
            <wc-toast></wc-toast>
            <Banner content={bannerDetails}></Banner>
            {
                categoryMovies && categoryMovies.map((category, i) => {
                    // console.log(category)
                    return (
                        <Carousel
                            // showTitles
                            key={i + Date.now()}
                            title={category.title}
                            items={category.content}
                        />
                    )
                })
            }
            <Footer />
        </>
    )
}

export default Home