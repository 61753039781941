import { Navigate } from "react-router-dom"
import { COOKIES } from "../../constants/global.const"
import routes from "../../constants/routes.const"

const ProtectedRoute = ({ children }) => {
    const userInfoCookie = COOKIES.get("user_info")

    if (!userInfoCookie) return <Navigate replace to={routes.signup} />
    return children
}

export default ProtectedRoute