import { faqContent, faqConfig, faqStyles } from "../constants/faq.const"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "wc-toast"
import { sendLog, setDeviceInfoCookies } from "../redux/functions/auth"
import Faq from "react-faq-component"
import Footer from "../components/Footer"
import Header from "../components/Header"
import devicesImg from "../assets/devices.png"
import dataFriendlyImg from "../assets/data-friendly.png"
import appStore from "../assets/app-store.png"
import googlePlay from "../assets/google-play.png"
import ReactPlayer from "react-player"
import routes from "../constants/routes.const"

const Landing = () => {
    const navigate = useNavigate()
    const [phoneNumber, setPhoneNumber] = useState("")
    const [isPlaying, setIsPlaying] = useState(true)

    useEffect(() => {
        setDeviceInfoCookies()
    }, [])

    useEffect(() => {
        const handleScroll = (event) => {
            if (window.scrollY > 150) setIsPlaying(!isPlaying)
            if (window.scrollY < 150) setIsPlaying(true);
        }
        window.addEventListener("scroll", handleScroll)
        return () => { window.removeEventListener("scroll", handleScroll) }
    }, [])

    useEffect(() => {
        const initSendLogs = async () => {
            await sendLog({ action: 'visit' })
        }
        initSendLogs()
    }, [])

    const handlePhoneNumberInput = e => {
        const text = e.target.value
        const limit = 10;
        if (isNaN(Number(text))) return
        setPhoneNumber(text.slice(0, limit))
    }

    const initAuth = () => {
        if (phoneNumber.length < 10) {
            toast.error("Enter a valid mobile number")
            return
        }

        localStorage.setItem("_tva_mobile_number", phoneNumber)
        navigate(routes.signup)
    }

    return (
        <>
            <Header />

            {/* <div className="py-20 px-5 w-screeen relative"> */}
            {/* <div className="flex items-center justify-center flex-col h-screen"> */}
            <div className="p-5 lg:h-screen mt-[calc(100vh/4)]">
                <h1 className="max-w-2xl m-auto text-center text-2xl lg:text-4xl">Get endless <span className="text-2xl lg:text-5xl text-secondary">entertainment, series, live events,</span> TV Shows and the <span className="text-2xl lg:text-5xl text-secondary">movies</span> you love.</h1>
                <h3 className="text-center mt-10">Sign Up To Enjoy Must-See TV</h3>
                <div className="flex items-center justify-center my-5 w-full">
                    <input value={phoneNumber} onChange={handlePhoneNumberInput} className="text-black p-2 w-full max-w-[300px] py-2 text-sm outline-none border-none" placeholder="0541234567" />
                    <button onClick={initAuth} className="bg-secondary text-[500] text-sm py-[9px] ml-[2px] px-2">Sign Up</button>
                </div>
            </div>
            <div className="absolute top-[-60px] -z-10 left-0">
                <div className="bg-[#1f1125e5] absolute h-screen w-screen left-0" />
                <ReactPlayer
                    url="https://glonigeria.tvanywhereafrica.com:28182/auth-streaming/2,13cfa118a055857531b245f6ab3814e44bbecd1b,1671546492,afpgh1111111111,0-New_Money_Trailer_3_1080p-hls-NONE,8,8,8,8,8,8,DESKTOP,143475,all,none,afriplayghana,172.20.1.31/hls/vod/0-New_Money_Trailer_3_1080p-hls-NONE/playlist.m3u8"
                    width="100vw"
                    height="100vh"
                    muted={true}
                    autoPlay={true}
                    playing={isPlaying}
                    volume={1}
                />
            </div>
            {/* </div > */}

            <section>
                <div className={styles.sectionWrapper}>
                    <div className="flex-1">
                        <img className="w-full max-w-[400px]" src={devicesImg} alt="afriplay_devices" />
                    </div>
                    <div className="flex-1 text-center">
                        <h1 className={styles.h2}>All Your Devices</h1>
                        <p>Stream movies, series, live Channels entertainment, across all your devices from one subscription. From GHC5.</p>
                        <div className="bg-[#F1F1F1] w-max rounded-md p-1 m-auto mt-5">
                            <img src={appStore} className="hover:opacity-80 transition-all cursor-pointer" alt="app_store_icon" />
                            <img src={googlePlay} className="hover:opacity-80 transition-all cursor-pointer" alt="google_play_icon" />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className={styles.sectionWrapper}>
                    <div className="flex-1 text-center">
                        <h1 className={styles.h2}>Data Friendly</h1>
                        <p>Save your data. Enjoy for longer. Watch at your preferred streaming quality.</p>
                    </div>
                    <div className="flex-1 lg:ml-10">
                        <img className="w-full max-w-[400px]" src={dataFriendlyImg} alt="afriplay_devices" />
                    </div>
                </div>
            </section>

            <div className="p-5">
                <Faq
                    data={faqContent}
                    styles={faqStyles}
                    config={faqConfig}
                />
            </div>

            <Footer />
        </>
    )
}

const styles = {
    h2: `lg:text-3xl text-xl font-[500] mb-5`,
    sectionWrapper: `flex lg:flex-row p-5 flex-col items-center max-w-[1000px] m-auto py-32 pt-0`,
}

export default Landing