const getRandomIndexes = (items = []) => {

    // console.warn("items", items)

    const min = items.length > 9 ? 9 : 1;
    const max = items.length >= 11 ? 11 : items.length;
    const _randomIndexes = []

    // console.log(min, max)

    for (var i = 1; i < items.length; i++) {
        var random = Math.round(Math.random() * (max - min) + min)
        if (!_randomIndexes.includes(random)) _randomIndexes.push(random)
    }

    // console.log("_randomIndexes", _randomIndexes)

    return _randomIndexes
}

export default getRandomIndexes