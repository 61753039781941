import { Link } from "react-router-dom"
import logo from "../../assets/logo.png"
import routes from "../../constants/routes.const"

const Footer = () => {
    return (
        <footer className="p-5 bg-[#090016] relative">
            <div className={styles.footerWrapper}>
                <img className="w-full h-full max-w-[90px] mb-10 lg:mb-0" src={logo} alt="afriplay_logo" />
                {/* <div>
                    <Link to={routes.policy}>
                        <small>Our Policy</small>
                    </Link>
                </div> */}
                <div>
                    <ul className="flex flex-col text-left text-sm">
                        <Link to={routes.home}>
                            <small className="text-[13px]">Featured</small>
                        </Link>
                        <Link to={routes.movies}>
                            <small className="text-[13px]">Movies</small>
                        </Link>
                        <Link to={routes.livetv}>
                            <small className="text-[13px]">Live TV</small>
                        </Link>
                        <Link to={routes.policy}>
                            <small className="text-[13px]">Our Policy</small>
                        </Link>
                    </ul>
                </div>
            </div>
            <div className="text-center text-[13px]  ">
                &copy;{new Date().getFullYear()} All Rights Reserved
            </div>
        </footer>
    )
}

const styles = {
    footerWrapper: `flex flex-wrap justify-between p-5 text-center text-sm m-auto max-w-[1100px]`,
}

export default Footer